<template>
  <div class="card card-body shadow announce-app">
    <app-modal-notification
      @showDataPreview="showDataPreview"
      ref="modal_notification_preview"
      :headerTitle="headerTitle"
      :dataPreview.sync="dataPreview"
      :action="action"
      @actionTrigger="handlerActionTrigger"
    >
    </app-modal-notification>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.ANNOUNCEMENTS_MNG_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:body-cell-display="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              @click="handleBtnPreviewClick(props.row)"
              class="btn btn-primary"
            >詳細
            </button>
          </td>
        </template>
        <template v-slot:filters>
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-6">
              <div class="form-group">
                <label>{{ filters.keyword_like.label }}</label>
                <app-input :name="filters.keyword_like.name" input-style="normal"
                          v-model="filters.keyword_like.value"
                />
              </div>
            </div>
          </div>
        </template>

        <template v-slot:body-cell-contents="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.contents" id="cong" class="app-table-p app-cell-tooltip mb-0">{{strip(props.row.contents)}}</p>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import AppModalNotification from "@components/_common/popup/AppModalNotification";
import {ANNOUNCEMENTS_MNG_CONSTANT} from "@constants/announcement_mng";

export default {
  name: "AnnouncementListPage",
  components: {
    "app-basic-table": AppBasicTable,
    AppModalNotification
  },
  data() {
    return {
      idCache: null,
      filters: {
        keyword_like: {
          name: "keyword",
          condition: "like",
          label: this.$t("announcements.keyword")
        },
      },
      columns: [
        {name: "title", label: this.$t("announcements.title"), sortable: true},
        {name: "contents", label: this.$t("announcements.contents")},
        {name: "created_at", label: this.$t("announcements.created_at"), textAlign: 'text-center', sortable: true},
        {name: "display", label: this.$t("announcements.display"), textAlign: 'text-center'},
      ],
      meta: {

      },
      dataPreview : {
        title : "",
        contents : ""
      },
      action : 0,
      headerTitle: ""
    };
  },

  methods: {
    async handleBtnPreviewClick(value) {
      this.announcementId = value.id;
      const res = await this.$request.get(this.ENDPOINT.UI_HELPER_ANNOUNCEMENTS(this.announcementId));
      this.check = res.data.announcement.length
      if (this.check == 0) {
        this.action = 0
        setTimeout(() => this.$router.go(0),500);
      } else {
        this.dataPreview = value;
        this.headerTitle = "お知らせ"
        this.action = ANNOUNCEMENTS_MNG_CONSTANT.PREVIEW_ACTION
        await this.$refs.modal_notification_preview.show()
      }
    },
    handlerActionTrigger() {
      this.action = 0
      this.onResetAllSearch()
    },
    async showDataPreview() {
      this.$refs.modal_notification_preview.hide();
    },
    strip(html){
      let doc = new DOMParser().parseFromString(html, 'text/html');
      let text = doc.body.textContent;
      if (text.trim().length > 30) {
        return text.trim().slice(0,30).concat('...')
      } else {
        return text.trim() || "...";
      }
    },
    onSearch() {

    },
    onResetAllSearch() {
      this.$refs.basic_table.getEntries();
      this.$router.push({
        name: this.$route.name,
      }).catch(e => {
        console.log('no warning console');
      })
    },
  },
}
</script>

<style>

  .announce-app .app-table-container .table th:nth-child(1) {
    width: 30%;
  }

  .announce-app .app-table-container .table th:nth-child(2) {
    width: 30%;
  }

  .announce-app .app-table-container .table th:nth-child(3) {
    width: 25%;
  }

  .announce-app .app-table-container .table th:nth-child(4) {
    width: 15%;
  }
</style>
